<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="m-2">
        <div class="form-row justify-content-end">
          <!--ID -->
          <div class="col-md-1">
            <div class="form-group mb-md-0 mb-1">
              <input
                type="search"
                class="form-control d-inline-block"
                placeholder="ID"
                v-model="filters.id"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0 mb-1">
              <flat-pickr
                class="form-control"
                placeholder="Data de criação"
                v-model="filters.date"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>
          <div class="col-md-7">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                ref="selectTeacher"
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.teachers"
                placeholder="Digite o nome do professor"
                :options="optionsTeachers"
                @search="fetchTeachers"
                multiple
              >
                <span
                  slot="no-options"
                  @click="$refs.selectTeacher.open = false"
                >
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-1">
            <button
              @click="search"
              style="padding: 14px !important"
              type="button"
              class="btn btn-primary btn-block"
            >
              <i v-if="!btnSearch" class="bi bi-search"></i>
              <b-spinner v-else small variant="light" />
            </button>
          </div>
        </div>
      </div>
    </b-card>

    <b-card no-body>
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="filters.perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
        </b-form-row>
      </div>
      <b-table
        :no-border-collapse="true"
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        sort-by="id"
        sort-desc
        :busy="loading"
        hover
        empty-text="Nenhum ponto encontrado."
      >
        <template #cell(items)="data">
          <i class="bi bi-box-seam"></i>
          {{ data.item.items.length }}
        </template>
        <template #cell(total)="data">
          {{ data.item.total | toCurrency }}
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | datePT(true) }}
        </template>
        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="TrashIcon"
              v-if="$can('Relatório de Ponto - Excluir', 'Pedagógico')"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />
            <feather-icon
              icon="EyeIcon"
              v-if="$can('Relatório de Ponto - Visualizar', 'Pedagógico')"
              class="cursor-pointer cursor"
              size="16"
              @click="goToReport(data.item.uuid)"
            />
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rowsTable="rowsTable"
        :currentPage="filters.currentPage"
        :perPage="filters.perPage"
        :totalRows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import { BButton, BCard, BCol, BFormRow, BTable } from "bootstrap-vue";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
export default {
  components: {
    BTable,
    BButton,
    BCard,
    BCol,
    BFormRow,
    CustomPaginateTable,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      totalRows: 0,
      rowsTable: 0,
      loading: false,
      dataItems: [],
      optionsTeachers: [],
      btnSearch: false,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "150",
          value: 150,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      filters: {
        currentPage: 1,
        perPage: 100,
        id: "",
      },
      tableColumns: [
        { key: "id", label: "ID", sortable: true },
        { key: "user.full_name", label: "Criado por" },
        { key: "items", label: "Itens" },
        { key: "total", label: "Total" },
        { key: "created_at", label: "Data", sortable: true },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 110px",
        },
      ],
    };
  },
  async created() {},
  async mounted() {
    await this.getData();

    this.$root.$on("paginate:update", this.handlePaginateUpdate);
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  methods: {
    handlePaginateUpdate(res) {
      this.filters.currentPage = res._currentPage;
      this.filters.perPage = res._perPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.getData();
    },
    async getData() {
      try {
        this.loading = true;

        const response = await this.$store.dispatch(
          "Grid/getReportsTeacherPoints",
          this.filters
        );
        this.dataItems = response.data;
        this.rowsTable = response.data.length;
        this.totalRows = response.meta.total;
        this.filters.currentPage = response.meta.current_page;
      } catch (error) {
      } finally {
        this.loading = false;
        this.btnSearch = false;
      }
    },

    goToReport(uuid) {
      this.$router.push(`/pedagogical/units/teacher-points/report/${uuid}`);
    },
    async confirmDelete(uuid) {
      this.$swal({
        title: "Deletar relatório de pontos?",
        text: "Tem certeza de que deseja deletar o relatório? Esta ação não pode ser desfeita.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, deletar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            await this.$store.dispatch("Grid/deleteReportTeacherPoints", uuid);
            this.$swal({
              title: "Relatório deletado!",
              text: "O relatório foi deletado com sucesso.",
              icon: "success",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } catch (error) {
            this.$swal({
              title: "Erro ao deletar o relatório",
              text: "Ocorreu um erro ao deletar do relatório. Por favor, tente novamente.",
              icon: "error",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        }
      });
    },
    search() {
      this.btnSearch = true;
      this.getData();
    },
    async fetchTeachers(term) {
      if (term.length > 2) {
        this.optionsTeachers = await this.$store.dispatch(
          "Teacher/search",
          term
        );
      }
    },
    async setPerPageSelected(obj) {
      this.filters.perPage = obj.value;
      await this.getData();
    },
  },
};
</script>

<style lang="scss" scoped></style>

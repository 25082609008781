var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "mb-1", attrs: { "no-body": "" } }, [
        _c("div", { staticClass: "m-2" }, [
          _c("div", { staticClass: "form-row justify-content-end" }, [
            _c("div", { staticClass: "col-md-1" }, [
              _c("div", { staticClass: "form-group mb-md-0 mb-1" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.id,
                      expression: "filters.id",
                    },
                  ],
                  staticClass: "form-control d-inline-block",
                  attrs: { type: "search", placeholder: "ID" },
                  domProps: { value: _vm.filters.id },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "id", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Data de criação",
                      config: {
                        mode: "range",
                        altInput: true,
                        dateFormat: "Y-m-d",
                        altFormat: "d/m/Y",
                        locale: "pt",
                      },
                    },
                    model: {
                      value: _vm.filters.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "date", $$v)
                      },
                      expression: "filters.date",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-7" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0 mb-1" },
                [
                  _c(
                    "v-select",
                    {
                      ref: "selectTeacher",
                      attrs: {
                        label: "title",
                        "item-text": "title",
                        "item-value": "code",
                        placeholder: "Digite o nome do professor",
                        options: _vm.optionsTeachers,
                        multiple: "",
                      },
                      on: { search: _vm.fetchTeachers },
                      model: {
                        value: _vm.filters.teachers,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "teachers", $$v)
                        },
                        expression: "filters.teachers",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          attrs: { slot: "no-options" },
                          on: {
                            click: function ($event) {
                              _vm.$refs.selectTeacher.open = false
                            },
                          },
                          slot: "no-options",
                        },
                        [_vm._v(" Nenhum registro encontrado ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-1" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-block",
                  staticStyle: { padding: "14px !important" },
                  attrs: { type: "button" },
                  on: { click: _vm.search },
                },
                [
                  !_vm.btnSearch
                    ? _c("i", { staticClass: "bi bi-search" })
                    : _c("b-spinner", {
                        attrs: { small: "", variant: "light" },
                      }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-form-row",
                { staticClass: "justify-content-between" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-none d-sm-block align-items-center justify-content-left",
                      attrs: { cols: "4", md: "2" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "title",
                          required: "",
                          options: _vm.perPageOptions,
                          clearable: false,
                        },
                        on: { input: _vm.setPerPageSelected },
                        model: {
                          value: _vm.filters.perPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "perPage", $$v)
                          },
                          expression: "filters.perPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "no-border-collapse": true,
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              striped: "",
              "sort-by": "id",
              "sort-desc": "",
              busy: _vm.loading,
              hover: "",
              "empty-text": "Nenhum ponto encontrado.",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(items)",
                fn: function (data) {
                  return [
                    _c("i", { staticClass: "bi bi-box-seam" }),
                    _vm._v(" " + _vm._s(data.item.items.length) + " "),
                  ]
                },
              },
              {
                key: "cell(total)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("toCurrency")(data.item.total)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("datePT")(data.item.created_at, true)) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-between" },
                      [
                        _vm.$can("Relatório de Ponto - Excluir", "Pedagógico")
                          ? _c("feather-icon", {
                              staticClass: "cursor-pointer cursor text-danger",
                              attrs: { icon: "TrashIcon", size: "16" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDelete(data.item.uuid)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.$can(
                          "Relatório de Ponto - Visualizar",
                          "Pedagógico"
                        )
                          ? _c("feather-icon", {
                              staticClass: "cursor-pointer cursor",
                              attrs: { icon: "EyeIcon", size: "16" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToReport(data.item.uuid)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              rowsTable: _vm.rowsTable,
              currentPage: _vm.filters.currentPage,
              perPage: _vm.filters.perPage,
              totalRows: _vm.totalRows,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }